import { postNewRecording } from '../../api/backendApi';
import { DISPLAY_ONLY_IN_SESSION, MAX_ALLOWED_CONFERENCE_USERS } from '../../config';
import { getComplexObject } from '../../helper/complexObjectStorage';
import { createKpiLog } from '../../helper/helper';
import { fetchVideoAndThumbnailAndDispatch, getURLParams } from '../../helper/rtcFlowHandling';
import { addCallerVideoStreamInDiv } from '../../helper/streamHandling';
import {
    addCallerAudioStream,
    addCallerStream,
    addConferenceUserStream,
    removeCallerAudioStream,
    removeConferenceUserStream,
} from '../../redux/actionCreators/actionCreators';
import {
    deactivateAudioStreamDispatcherDispatch,
    deactivateBidiDispatch,
    deactivateScreenshareDispatch,
    dispatchCallerPageNotLoaded,
} from '../../redux/actions/application';
import { dispatchAddStreamInfo, dispatchRemoveStreamInfo } from '../../redux/actions/conferencing';
import { connectionLostDispatch } from '../../redux/actions/connection';
import { changeUsageDisclaimerStateCallerDispatch } from '../../redux/actions/disclaimers';
import { dispatchAddInvitedUser, dispatchRemoveInvitedUser, dispatchToggleAudioMuted } from '../../redux/actions/invitedUsers';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { dispatchAddSessionRecording } from '../../redux/actions/session';
import {
    addSessionDevice,
    deactivateSessionHandover,
    deactivateSessionHijacking,
    replaceCallerDevice,
    unsetIsHeadMountedDisplayDevice,
    updateActiveDeviceId,
} from '../../redux/slices/sessionHandlingSlice';
import store from '../../redux/store';
import reduxStore from '../../redux/store';
import { serializeContact } from '../../redux/utils/serializeContact';
import { serializeStreamInfo } from '../../redux/utils/serializeStreamInfo';
import { sixDigitRegex } from '../../regex';
import { WebRtcMessageDispatcher } from '../../types';
import {
    sendApplicationData,
    sendDispatcherLeftToCaller,
    sendDispatcherLeftToUser,
    sendHandoverSessionToCallerDevice,
    sendJoinRequestGrantedToUser,
    sendSessionData,
    sendSessionHandlingData,
} from '../outgoingMessages/outgoingMessagesDispatcher';
import { dispatcherWebRTCManager } from '../webrtcManagers/DispatcherWebRTCManager';

// handlers for the dispatcher session and conversation
export const loadEventListenersDispatcher = () => {
    // both the caller and invited users joining the session
    dispatcherWebRTCManager.connectedSession.on('contactListUpdate', handleUserJoined);

    dispatcherWebRTCManager.connectedConversation
        .on('streamAdded', handleStreamAdded)
        .on('streamRemoved', handleStreamRemoved)
        .on('streamListChanged', handleStreamChanged)
        .on('contactJoined', handleContactJoinedDispatcher)
        .on('contactLeft', handleContactLeftDispatcher)
        .on('recordingStarted', handleStreamRecordingStarted)
        .on('recordingStopped', handleStreamRecordingStopped)
        .on('recordingAvailable', handleStreamRecordingIsAvailable);
};

export const unloadEventListenersDispatcher = () => {
    dispatcherWebRTCManager.connectedSession.removeListener('contactListUpdate', handleUserJoined);

    dispatcherWebRTCManager.connectedConversation
        .removeListener('streamAdded', handleStreamAdded)
        .removeListener('streamRemoved', handleStreamRemoved)
        .removeListener('streamListChanged', handleStreamChanged)
        .removeListener('contactJoined', handleContactJoinedDispatcher)
        .removeListener('contactLeft', handleContactLeftDispatcher)
        .removeListener('recordingStarted', handleStreamRecordingStarted)
        .removeListener('recordingStopped', handleStreamRecordingStopped)
        .removeListener('recordingAvailable', handleStreamRecordingIsAvailable);
};

const handleUserJoined = updatedContact => {
    // proceed only if updatedContact contains any user data
    if (updatedContact.userDataChanged.length > 0) {
        const { callerId } = getURLParams();
        const joiningUserObject = updatedContact.userDataChanged[0];
        const joiningUserDeviceSerialized = serializeContact(updatedContact.userDataChanged[0]);

        // validate joining user data
        if (callerId && joiningUserObject?.userData?.username?.length > 0 && joiningUserDeviceSerialized?.contact?.userData?.username?.length > 0) {
            const joiningUser = { username: joiningUserObject.userData.username, deviceId: joiningUserObject.userData.id };

            // 1. activeDeviceId does not yet exist (caller's username will always match callerId URL param)
            if (reduxStore.getState().sessionHandlingSlice.devices.length === 0 && joiningUser.username === callerId) {
                store.dispatch(addSessionDevice(joiningUserDeviceSerialized));
                store.dispatch(updateActiveDeviceId(joiningUser.deviceId));
                sendJoinRequestGrantedToUser(joiningUserObject);
                dispatcherWebRTCManager.establishConnectivityWithNewCaller(joiningUserObject);
                return;
            }

            // 2. activeDeviceId exists. A username that matches the callerId attempts to join
            if (reduxStore.getState().sessionHandlingSlice.devices.length !== 0 && joiningUser.username === callerId) {
                // check if joining user has previously joined
                const deviceIdExists = reduxStore.getState().sessionHandlingSlice.devices.some(device => device.contact.userData.id === joiningUser.deviceId);

                // session handover is active - we handover the session to the joining user
                if (reduxStore.getState().sessionHandlingSlice.sessionHandoverIsActive) {
                    // reset dispatcher dashboard states while caller disclaimer is active
                    if (reduxStore.getState().features.disclaimerFeatureCaller && reduxStore.getState().disclaimers.usageDisclaimerStateCaller !== 'none') {
                        resetDispatcherDashboardStates();
                    }

                    // reset head mounted display device state
                    if (reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice) {
                        store.dispatch(unsetIsHeadMountedDisplayDevice());
                    }
                    // send session handover success message to current caller device
                    sendHandoverSessionToCallerDevice();

                    // update active device id
                    store.dispatch(updateActiveDeviceId(joiningUser.deviceId));

                    if (!deviceIdExists) {
                        store.dispatch(addSessionDevice(joiningUserDeviceSerialized));
                    }

                    sendJoinRequestGrantedToUser(joiningUserObject);

                    // we need a timeout here, since the messages can be sent in the wrong order
                    // and we need to wait for the correct detection of the new caller device
                    setTimeout(() => {
                        store.dispatch(deactivateSessionHandover());
                    }, 500);
                    dispatcherWebRTCManager.establishConnectivityWithNewCaller(joiningUserObject);
                    createKpiLog('sessionHandoverSuccessful', '', { 0: joiningUser.deviceId });
                    dispatcherWebRTCManager.toggleOffAllFeatures();
                    addNotificationAndShowDispatch('session.handover.success', 'info', DISPLAY_ONLY_IN_SESSION);
                    store.dispatch(deactivateSessionHijacking());
                    return;
                }

                // session handover is not active
                if (!reduxStore.getState().sessionHandlingSlice.sessionHandoverIsActive) {
                    // rejoining device - we handover the session
                    if (deviceIdExists) {
                        // reset dispatcher dashboard states while caller disclaimer is active
                        if (reduxStore.getState().features.disclaimerFeatureCaller && reduxStore.getState().disclaimers.usageDisclaimerStateCaller !== 'none') {
                            resetDispatcherDashboardStates();
                        }

                        // reset head mounted display device state
                        if (reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice) {
                            store.dispatch(unsetIsHeadMountedDisplayDevice());
                        }

                        if (joiningUser.deviceId !== reduxStore.getState().sessionHandlingSlice.activeDeviceId) {
                            // send session handover success message to current caller device
                            sendHandoverSessionToCallerDevice();

                            // update active device id
                            store.dispatch(updateActiveDeviceId(joiningUser.deviceId));

                            sendJoinRequestGrantedToUser(joiningUserObject);
                            dispatcherWebRTCManager.establishConnectivityWithNewCaller(joiningUserObject);
                            createKpiLog('callerDeviceChanged', '', { 0: joiningUser.deviceId });
                            addNotificationAndShowDispatch('session.device.changed', 'info', DISPLAY_ONLY_IN_SESSION);
                            dispatcherWebRTCManager.toggleOffAllFeatures();
                            store.dispatch(deactivateSessionHijacking());
                        }
                        return;
                    }

                    // new caller device attempts to join
                    if (!deviceIdExists) {
                        // only proceed if session connectivity issues exist or if session hijacking is active
                        if (!reduxStore.getState().connection.isConnected || reduxStore.getState().sessionHandlingSlice.sessionHijackingIsActive) {
                            // reset dispatcher dashboard states while caller disclaimer is active
                            if (reduxStore.getState().features.disclaimerFeatureCaller && reduxStore.getState().disclaimers.usageDisclaimerStateCaller !== 'none') {
                                resetDispatcherDashboardStates();
                            }

                            // reset hmd
                            if (reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice) {
                                store.dispatch(unsetIsHeadMountedDisplayDevice());
                            }
                            // if the connection is lost or session hijacking is active, allow new device to hijack the session
                            const currentCallerDevice = reduxStore
                                .getState()
                                .sessionHandlingSlice.devices.find(
                                    device => device.contact.userData.id === reduxStore.getState().sessionHandlingSlice.activeDeviceId
                                );

                            // send disconnect message to current caller device and remove from device list
                            sendDispatcherLeftToCaller();

                            // replace current caller device with joining user device
                            store.dispatch(replaceCallerDevice({ currentDevice: currentCallerDevice, newDevice: joiningUserDeviceSerialized }));
                            store.dispatch(updateActiveDeviceId(joiningUser.deviceId));
                            sendJoinRequestGrantedToUser(joiningUserObject);
                            dispatcherWebRTCManager.establishConnectivityWithNewCaller(joiningUserObject);
                            dispatcherWebRTCManager.toggleOffAllFeatures();
                            store.dispatch(deactivateSessionHijacking());
                        } else {
                            // send disconnect message to new device attempting to connect
                            sendDispatcherLeftToUser(joiningUserObject);
                        }
                    }
                }
            }

            /**
             * INVITED USERS ONLY
             **/
            if (reduxStore.getState().features.inviteFeature && joiningUserDeviceSerialized.contact.userData.username !== callerId) {
                if (reduxStore.getState().invitedUsers.length > 0) {
                    reduxStore.getState().invitedUsers.map(currentUser => {
                        // check if user exists via user id to prevent adding a duplicate on name change
                        const userExists = currentUser.userData.id === joiningUser.deviceId;
                        if (userExists) {
                            dispatchRemoveInvitedUser(currentUser);
                        }
                        return null;
                    });

                    if (
                        joiningUserDeviceSerialized.contact.userData.username.indexOf('guest-') === -1 &&
                        !sixDigitRegex.test(joiningUserDeviceSerialized.contact.userData.username)
                    ) {
                        dispatchAddInvitedUser(joiningUserDeviceSerialized.contact);
                        const responseMessage = {
                            data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
                        };

                        dispatcherWebRTCManager.sendMessageToUser(responseMessage, joiningUserObject);
                        sendSessionData();
                        sendApplicationData();
                        sendSessionHandlingData();
                    }
                } else {
                    // sixDigitRegex prevents users with a webrtc randomly generated 6 digit name from being added
                    if (
                        joiningUserDeviceSerialized.contact.userData.username.indexOf('guest-') === -1 &&
                        !sixDigitRegex.test(joiningUserDeviceSerialized.contact.userData.username)
                    ) {
                        dispatchAddInvitedUser(joiningUserDeviceSerialized.contact);
                        const responseMessage = {
                            data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
                        };

                        dispatcherWebRTCManager.sendMessageToUser(responseMessage, joiningUserObject);
                        sendSessionData();
                        sendApplicationData();
                        sendSessionHandlingData();
                    }
                }
            }
        }
    }
};

// reset disaptcher dashboard states for re-entering or reloading caller device

const resetDispatcherDashboardStates = () => {
    // reset for new caller device
    changeUsageDisclaimerStateCallerDispatch('waiting');
    dispatchCallerPageNotLoaded();
    if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
    if (reduxStore.getState().application.screenshareIsActive) deactivateScreenshareDispatch('dispatcher');
};

// On incoming stream event, place in corresponding div container, add stream object to redux store, and subscribe to stream

const handleStreamAdded = stream => {
    const { callerId } = getURLParams();
    if (stream.contact.userData.username === callerId) {
        // incoming caller stream
        if (stream.type === 'audio') {
            store.dispatch(addCallerAudioStream(stream));
            handleIncomingAudio();
        }
        if (stream.type === 'video') {
            store.dispatch(addCallerStream(stream));
            addCallerVideoStreamInDiv(stream, true);
        }
    } else {
        // incoming conference user stream
        const additionalStates = {
            0: stream.contact.userData.username,
        };

        createKpiLog('infoConferenceUser', 'joined', additionalStates);

        store.dispatch(addConferenceUserStream(stream));
        handleIncomingAudio();
    }
};

const handleStreamRemoved = stream => {
    const { callerId } = getURLParams();

    if (stream.contact.userData.username === callerId) {
        // caller stream
        if (stream.type === 'audio') {
            store.dispatch(removeCallerAudioStream(stream));
            handleRemovalOfIncomingStream(stream);
        }
    } else {
        const additionalStates = {
            0: stream.contact.userData.username,
        };

        createKpiLog('infoConferenceUser', 'left', additionalStates);

        store.dispatch(removeConferenceUserStream(stream));
        handleRemovalOfIncomingStream(stream);
    }
};

const handleStreamChanged = streamInfo => {
    if (streamInfo.isRemote === true) {
        if (streamInfo.listEventType === 'added') {
            dispatchAddStreamInfo(serializeStreamInfo(streamInfo));
            dispatcherWebRTCManager.connectedConversation
                .subscribeToMedia(streamInfo.streamId)
                .then(stream => {
                    console.log('subscribeToMedia succeeded');

                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                })
                .catch(err => {
                    console.error('subscribeToMedia error', err);
                });
        }
        if (streamInfo.listEventType === 'removed') {
            dispatchRemoveStreamInfo(streamInfo.streamId);
        }
        if (streamInfo.listEventType === 'updated') {
            for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                if (streamInfo.streamId === previousStreamInfo.streamId) {
                    dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                    dispatchAddStreamInfo(serializeStreamInfo(streamInfo));

                    // toggles the audio muted information in the conference user list
                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                }
            }
        }
    }
};

const handleRemovalOfIncomingStream = stream => {
    const { callerId } = getURLParams();

    // caller audio stream
    if (stream.contact.userData.username === callerId) {
        if (reduxStore.getState().application.audioStreamIsActive) {
            addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
        }

        deactivateAudioStreamDispatcherDispatch();
    }

    stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId);
};

// AUDIO

const handleIncomingAudio = () => {
    if (!reduxStore.getState().application.audioIsMuted) {
        readdAllStreamsToContainerDispatcher();
    }
};

// ADD AND REMOVE STREAMS TO CONTAINER DISPATCHER

export const readdAllStreamsToContainerDispatcher = () => {
    removeAllStreamsFromContainerDispatcher();
    addAllStreamsToContainerDispatcher();
};

export const addAllStreamsToContainerDispatcher = () => {
    if (reduxStore.getState().streamSlice.callerAudioStream) {
        // fetch stream object from complex object storage
        const stream = getComplexObject(reduxStore.getState().streamSlice.callerAudioStream.streamId);
        if (stream) {
            stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        }
    }
    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            const stream = getComplexObject(reduxStream.streamId);
            if (stream) {
                stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
            }
        });
    }
};

export const removeAllStreamsFromContainerDispatcher = () => {
    if (reduxStore.getState().streamSlice.callerAudioStream) {
        // fetch stream object from complex object storage
        const stream = getComplexObject(reduxStore.getState().streamSlice.callerAudioStream.streamId);
        if (stream) {
            stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        }
    }
    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            const stream = getComplexObject(reduxStream.streamId);
            if (stream) {
                stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
            }
        });
    }
};

// CONTACT JOINED

const handleContactJoinedDispatcher = contactInfo => {
    const { callerId } = getURLParams();

    if (contactInfo.userData.username !== callerId && reduxStore.getState().invitedUsers.length < MAX_ALLOWED_CONFERENCE_USERS) {
        sendSessionData();
        sendApplicationData();
        sendSessionHandlingData();
    }
};

// CONTACT LEFT

const handleContactLeftDispatcher = contactInfo => {
    const { callerId } = getURLParams();
    // if current call leaves, set connection has been lost
    if (contactInfo.userData.username === callerId && contactInfo.userData.id === reduxStore.getState().sessionHandlingSlice.activeDeviceId) {
        connectionLostDispatch();
        return;
    }

    // if invited user leaves, remove from list
    if (contactInfo.userData.username !== callerId) {
        if (reduxStore.getState().invitedUsers.length > 0) {
            reduxStore.getState().invitedUsers.map(user => {
                if (user.userData.id === contactInfo.userData.id) {
                    const serializedContactInfo = serializeContact(contactInfo);
                    dispatchRemoveInvitedUser(serializedContactInfo.contact);
                }
                return null;
            });
        }
    }
};

const handleStreamRecordingStarted = recordingInfo => {
    let status = 'started';
    dispatchAddSessionRecording({ recordingInfo, status });
    postNewRecording(recordingInfo.mediaId, recordingInfo.mediaURL, recordingInfo.recordedFileName);
};

const handleStreamRecordingStopped = recordingInfo => {
    let status = 'stopped';
    dispatchAddSessionRecording({ recordingInfo, status });
};

const handleStreamRecordingIsAvailable = recordingInfo => {
    let status = 'available';
    dispatchAddSessionRecording({ recordingInfo, status });
    fetchVideoAndThumbnailAndDispatch(recordingInfo);
};
